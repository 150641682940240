import amplitude from 'amplitude-js';
import React, { FC, Fragment, PropsWithChildren, useCallback } from 'react';

import { ConsentListener, ICookiePolicy } from './type';

const defaultCookiePolicy: ICookiePolicy = {
  CookieProvider: Fragment,
  CookieComponent: () => null,
  useCookieConsents: () => ({}),
  getCookieConsents: () => ({}),
  showCookiePolicy: null,
};

let cookiePolicy = defaultCookiePolicy;

if (process.env.BRAND === 'UC') {
  // eslint-disable-next-line global-require
  cookiePolicy = require('./UC/UCCookiePolicy').UCCookiePolicy;
}

if (process.env.BRAND === 'LFP') {
  // eslint-disable-next-line global-require
  cookiePolicy = require('./LFP/LFPCookiePolicy').LFPCookiePolicy;
}

const CookieProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const handleConsent = useCallback<ConsentListener>((consent, accepted) => {
    if (consent === 'improveServices') {
      amplitude.getInstance()?.setOptOut(!accepted);
    }
  }, []);

  if (!cookiePolicy) {
    return null;
  }

  return (
    <cookiePolicy.CookieProvider onConsent={handleConsent}>
      {children}
    </cookiePolicy.CookieProvider>
  );
};

export const CookiePolicy = {
  ...cookiePolicy,
  CookieProvider,
};
